import {
  NgModule,
  ModuleWithProviders,
  Optional,
  SkipSelf,
} from '@angular/core'
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http'
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc'

import { AuthService } from './auth.service'
import { AuthGuard } from './auth.guard'
import { HttpInterceptorProviders } from './auth-interceptor'

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [CommonModule, HttpClientModule, OAuthModule.forRoot()],
  providers: [AuthService, AuthGuard],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        HttpInterceptorProviders,
        { provide: OAuthStorage, useFactory: storageFactory },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
