import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject, ReplaySubject, Observable, combineLatest } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc'
import { GetSettingsGQL, ClientSettings } from '../generated/graphql'

@Injectable({ providedIn: 'root' })
export class AuthService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false)
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable()

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>()
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable()

  private hasDebtorClaimSubject$ = new BehaviorSubject<boolean>(false)
  public hasDebtorClaim$ = this.hasDebtorClaimSubject$.asObservable()

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private settingsClient: GetSettingsGQL
  ) {
    this.oauthService.events.subscribe((_) => {
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken())
      this.hasDebtorClaimSubject$.next(this.hasDebtorClaim())
    })

    this.oauthService.events
      .pipe(filter((e) => ['token_received'].includes(e.type)))
      .subscribe(() => {
        this.oauthService.loadUserProfile()

        if (
          this.oauthService.state &&
          this.oauthService.state !== 'undefined' &&
          this.oauthService.state !== 'null'
        ) {
          let stateUrl = this.oauthService.state
          if (stateUrl.startsWith('/') === false) {
            stateUrl = decodeURIComponent(stateUrl)
          }

          this.router.navigateByUrl(stateUrl)
        }
      })

    this.oauthService.events
      .pipe(
        filter((e) => ['session_terminated', 'session_error'].includes(e.type))
      )
      .subscribe(() => this.oauthService.initLoginFlow())

    this.oauthService.setupAutomaticSilentRefresh()
  }

  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
  ]).pipe(map((values) => values.every((b) => b)))

  public login(targetUrl?: string) {
    this.oauthService.initLoginFlow(targetUrl || this.router.url)
  }

  public logout() {
    this.oauthService.logOut()
  }

  public runInitialLoginSequence(authConfig: AuthConfig): Promise<void> {
    return new Promise((resolve, reject) => {
      this.oauthService.configure(authConfig)
      this.oauthService
        .loadDiscoveryDocumentAndTryLogin()
        .then(() => this.oauthService.setupAutomaticSilentRefresh())

      this.isDoneLoadingSubject$.next(true)
      resolve()
    })
  }

  public get identityClaims() {
    return this.oauthService.getIdentityClaims()
  }

  public get accessToken() {
    return this.oauthService.getAccessToken()
  }

  get email() {
    return this.oauthService.getIdentityClaims()
      ? this.oauthService.getIdentityClaims()['email']
      : '-'
  }

  private hasDebtorClaim(): boolean {
    const claims = this.oauthService.getIdentityClaims()

    if (claims === null) {
      return false
    }

    if (claims['debtor'] === undefined) {
      return false
    } else {
      return true
    }
  }
}
