import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from 'src/app/core/auth.service'
import { Observable } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  isAuthenticated$!: Observable<boolean>
  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit() {
    this.isAuthenticated$ = this.authService.isAuthenticated$

    this.authService.isDoneLoading$.pipe(
      switchMap((_) => {
        return this.authService.isAuthenticated$.pipe(
          switchMap((_) => this.validateDebtorClaim())
        )
      })
    )
  }

  private validateDebtorClaim(): Observable<boolean> {
    return this.authService.hasDebtorClaim$.pipe(
      map((hasDebtorClaim) => {
        if (hasDebtorClaim) {
          this.router.navigate(['/debtor'])
        } else {
          this.router.navigate(['/unauthorized'])
        }
        return hasDebtorClaim
      })
    )
  }
}
