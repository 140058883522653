import { NgModule } from '@angular/core'
import { Routes, RouterModule, PreloadAllModules } from '@angular/router'
import { LandingComponent } from './layout/landing/landing.component'
import { UnauthorizedComponent } from './layout/unauthorized/unauthorized.component'
import { AuthGuard } from './core/auth.guard'

const routes: Routes = [
  {
    path: 'debtor',
    loadChildren: () =>
      import('./modules/debtor/debtor.module').then((m) => m.DebtorModule),
    canActivate: [AuthGuard],
  },
  //{ path: '', component: LandingComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  //{ path: '**', redirectTo: '' },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
