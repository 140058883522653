import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  @Input()
  authorized = false

  @Input()
  email = ''

  @Output()
  signInClicked = new EventEmitter()

  @Output()
  signOutClicked = new EventEmitter()

  signIn() {
    this.signInClicked.emit()
  }

  signOut() {
    this.signOutClicked.emit()
  }
}
