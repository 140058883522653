<section class="landing">
  <h2>Welcome to the Debtor Number Generator!</h2>
  <button
    name="0780bd40-9e30-4edc-9ce2-b620c38cf5e8"
    *ngIf="!(isAuthenticated$ | async)"
    [routerLink]="['/debtor']"
    class="btn btn-primary"
  >
    Sign in
  </button>
</section>
