import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
  HttpHandler,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authService: AuthService;

  constructor(private injector: Injector) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let requestToForward = req;

    if (this.authService === undefined) {
      this.authService = this.injector.get(AuthService);
    }
    if (this.authService !== undefined) {
      const token = this.authService.accessToken;
      if (token !== '') {
        const tokenValue = 'Bearer ' + token;
        requestToForward = req.clone({
          headers: req.headers.set('Authorization', tokenValue),
        });
      }
    } else {
      console.log('OAuthService undefined: NO auth header!');
    }

    return next.handle(requestToForward);
  }
}

/** Http interceptor providers in outside-in order */
export const HttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
