import { Component, OnInit, Input } from '@angular/core'
import { ApplicationInformation } from 'src/app/generated'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input()
  info: ApplicationInformation = {}
}
