<clr-main-container>
  <app-navigation
    (signInClicked)="onSignInClicked($event)"
    (signOutClicked)="onSignOutClicked($event)"
    [authorized]="isAuthenticated$ | async"
    [email]="email"
  ></app-navigation>
  <div class="content-container">
    <main class="content-area">
      <app-landing *ngIf="!(isAuthenticated$ | async)"></app-landing>
      <router-outlet *ngIf="isAuthenticated$ | async"></router-outlet>
    </main>
  </div>
  <app-footer [info]="info"></app-footer>
</clr-main-container>
