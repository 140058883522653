import { Injectable } from '@angular/core'
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router'
import { AuthService } from './auth.service'
import { Observable, forkJoin } from 'rxjs'
import { filter, switchMap, tap, map, mergeMap } from 'rxjs/operators'

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    let doneLoading$ = this.authService.isDoneLoading$
    let isAuthenticated$ = this.validateAuthentication(state)
    let hasDebtorClaim$ = this.validateDebtorClaim()

    return doneLoading$.pipe(
      switchMap((_) => {
        return isAuthenticated$.pipe(switchMap((_) => hasDebtorClaim$))
      })
    )
  }

  private validateAuthentication(
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isAuthenticated$.pipe(
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          this.authService.login(state.url)
        }
        return isAuthenticated
      })
    )
  }

  private validateDebtorClaim(): Observable<boolean> {
    return this.authService.hasDebtorClaim$.pipe(
      map((hasDebtorClaim) => {
        if (!hasDebtorClaim) {
          this.router.navigate(['/unauthorized'])
        }
        return hasDebtorClaim
      })
    )
  }
}
