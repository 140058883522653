import { Component, OnInit } from '@angular/core'
import { Observable } from 'rxjs'

import { AuthService } from './core/auth.service'
import {
  ClientSettings,
  ApplicationInformation,
  GetSettingsGQL,
} from './generated'
import { AuthConfig } from 'angular-oauth2-oidc'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isAuthenticated$: Observable<boolean>
  info: ApplicationInformation = {}

  constructor(
    private authService: AuthService,
    private settingsClient: GetSettingsGQL
  ) {
    this.isAuthenticated$ = this.authService.isAuthenticated$
  }

  ngOnInit() {
    this.getSettings()
  }

  onSignInClicked($event: any) {
    this.authService.login()
  }

  onSignOutClicked($event: any) {
    this.authService.logout()
  }

  get email() {
    return this.authService.email
  }

  private getSettings() {
    this.settingsClient.watch().valueChanges.subscribe((result) => {
      if (result.errors) {
        result.errors.forEach((error) => {
          console.log(error.message)
        })
      } else {
        if (result.data.clientSettings !== null) {
          const authCodeFlowConfig: AuthConfig = {
            issuer: result.data.clientSettings.issuer,
            redirectUri: window.location.origin,
            postLogoutRedirectUri: window.location.origin,
            clientId: result.data.clientSettings.clientId,
            responseType: 'code',
            scope: result.data.clientSettings.scope,
            useSilentRefresh: true,
          }

          this.authService.runInitialLoginSequence(authCodeFlowConfig)
        }

        if (result.data.applicationInformation !== null) {
          this.info = result.data.applicationInformation
        }
      }
    })
  }
}
