import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};






export type ApplicationInformation = {
  __typename?: 'ApplicationInformation';
  copyright?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export enum ApplyPolicy {
  BeforeResolver = 'BEFORE_RESOLVER',
  AfterResolver = 'AFTER_RESOLVER'
}

export type ClientSettings = {
  __typename?: 'ClientSettings';
  clientId?: Maybe<Scalars['String']>;
  issuer?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
};

export type Debtor = {
  __typename?: 'Debtor';
  debtorNumber?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** The application infomation. */
  applicationInformation?: Maybe<ApplicationInformation>;
  /** The client settings. */
  clientSettings?: Maybe<ClientSettings>;
  /** Get debtor number by given company. */
  debtorNumber?: Maybe<Debtor>;
};


export type QueryDebtorNumberArgs = {
  companyId: Scalars['Int'];
};

export type DebtorNumberQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type DebtorNumberQuery = (
  { __typename?: 'Query' }
  & { debtorNumber?: Maybe<(
    { __typename?: 'Debtor' }
    & Pick<Debtor, 'debtorNumber'>
  )> }
);

export type GetSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSettingsQuery = (
  { __typename?: 'Query' }
  & { applicationInformation?: Maybe<(
    { __typename?: 'ApplicationInformation' }
    & Pick<ApplicationInformation, 'copyright' | 'title' | 'version'>
  )>, clientSettings?: Maybe<(
    { __typename?: 'ClientSettings' }
    & Pick<ClientSettings, 'clientId' | 'issuer' | 'scope'>
  )> }
);

export const DebtorNumberDocument = gql`
    query debtorNumber($companyId: Int!) {
  debtorNumber(companyId: $companyId) {
    debtorNumber
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DebtorNumberGQL extends Apollo.Query<DebtorNumberQuery, DebtorNumberQueryVariables> {
    document = DebtorNumberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSettingsDocument = gql`
    query getSettings {
  applicationInformation {
    copyright
    title
    version
  }
  clientSettings {
    clientId
    issuer
    scope
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSettingsGQL extends Apollo.Query<GetSettingsQuery, GetSettingsQueryVariables> {
    document = GetSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }