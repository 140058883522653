<clr-header>
  <div class="branding">
    <a [routerLink]="['/debtor']" class="nav-link">
      <span class="title">Debtor Number Generator</span>
    </a>
  </div>

  <div class="header-actions">
    <a
      href="javascript://"
      (click)="signIn()"
      *ngIf="!authorized"
      class="nav-link nav-icon-text"
    >
      <clr-icon shape="login"></clr-icon>
      <span class="nav-text">Sign in</span>
    </a>

    <clr-dropdown *ngIf="authorized">
      <button clrDropdownTrigger>
        <clr-icon shape="user" size="24"></clr-icon>
        <clr-icon shape="caret down"></clr-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <a href="javascript://" (click)="signOut()" clrDropdownItem
          ><span>Sign out</span></a
        >
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>
</clr-header>
